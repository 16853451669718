<template>
  <div class="team-member-div">
    <div class="s1-team-name">{{ name }}</div>
    <div class="s1-team-position">{{ position }}</div>
    <img :src="image" class="img-team" />
    <div>
      <el-link
        class="social-link"
        :href="`${twitterLink}`"
        target="_blank"
        :underline="false"
        v-if="twitterLink"
      >
        <img src="../../assets/icons/twitter-icon.svg" class="social-icon" />
      </el-link>
      <el-link
        class="social-link"
        :href="`${linkedInLink}`"
        target="_blank"
        :underline="false"
        v-if="linkedInLink"
      >
        <img src="../../assets/icons/linkedin-icon.svg" class="social-icon" />
      </el-link>
    </div>
    <p class="s1-team-bio" v-if="!readActivated">
      {{ formatBioPreview(bio)
      }}<span class="read-more-span" @click="toggleReadMore(readActivated)"
        >Read more</span
      >
    </p>
    <p class="s1-team-bio" v-if="readActivated">
      <span v-html="bio"></span
      ><span class="read-more-span" @click="toggleReadMore(readActivated)"
        >Read less</span
      >
    </p>
  </div>
</template>

<script>
  import { ref } from 'vue'
  export default {
    name: 'TeamMember',
    props: {
      name: String,
      position: String,
      image: String,
      bio: String,
      twitterLink: String,
      linkedInLink: String,
    },
    setup() {
      const readActivated = ref(false)

      const formatBioPreview = (bio) => {
        const cleanBio = bio.replace(/<\/?[^>]+(>|$)/g, '')
        const length = cleanBio.length
        let descritpionSuffix = length > 99 ? '...' : ''
        return `${cleanBio.substring(0, 100)}${descritpionSuffix}`
      }

      const toggleReadMore = (value) => {
        readActivated.value = !value
      }

      return {
        readActivated,
        toggleReadMore,
        formatBioPreview,
      }
    },
  }
</script>

<style scoped>
  .team-member-div {
    padding: 5%;
  }
  .img-team {
    border-radius: 15%;
    width: 80%;
    height: auto;
    margin-top: 3%;
  }
  .s1-team-name {
    font-weight: bolder;
    font-size: 1.5em;
    white-space: nowrap;
    margin-bottom: 10px;
  }
  .s1-team-position {
    font-size: 1em;
    white-space: nowrap;
  }
  .s1-team-bio {
    margin: 1em 0;
    padding: 0 3% 10px 3%;
    line-height: 1.5em;
    text-align: left;
  }
  .social-icon {
    min-width: 25px;
    filter: invert(52%) sepia(98%) saturate(1891%) hue-rotate(159deg)
      brightness(94%) contrast(93%);
  }
  .social-link {
    margin: 5% 5% 0%;
  }
  .read-more-span {
    color: blue;
    margin-left: 5px;
    cursor: pointer;
  }
</style>
