<template>
  <div class="about-container">
    <el-row style="display: flex; align-items: center; padding: 0 10%">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <img src="../assets/images/s1-logo.png" class="img-logo" />
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <h1>StudioUno</h1>
        <h3>One studio for all</h3>
        <div class="description">
          <p>
            Where artists and technologists collaborate to bring you interactive
            projects that explore the boundaries and intersection of their
            métier.
          </p>
          <p>
            Unleash your creativity. Mint incredible art that is significant to
            you. Join the thriving collector community. Gain access to exclusive
            experiences and rewards.
          </p>
          <p>
            We invite you to
            <a href="https://twitter.com/StudioUnoNFT" target="_blank"
              >follow us on Twitter</a
            >
            and
            <a href="https://discord.com/invite/kwbRuwf" target="_blank"
              >join our thriving community on Discord</a
            >
            to keep up with the latest on StudioUno.
          </p>
        </div>
      </el-col>
    </el-row>
    <h2 class="team-title">Team</h2>

    <el-row :gutter="10" style="padding: 0 5%">
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <TeamMember
          name="Sebastián Brocher"
          position="Founder & CEO"
          :image="require('../assets/images/team/s1-team1.jpg')"
          bio="Entrepreneur, Artist, Engineer. As founder at CryptoArte (first generative art collection on Ethereum), Sebastián has been on the web3 space since late 2017. A serial entrepreneur, Sebastián has founded 7 technology ventures over the last 20 years. His success with CryptoArte combined with his entrepreneurial passion led to the vision for StudioUno &mdash; an NFT art platform where artists, collectors, and technologists collaborate to innovate on the boundaries and intersections of art and technology."
          twitterLink="https://twitter.com/cryptoarte"
          linkedInLink="https://www.linkedin.com/in/sebbro/"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <TeamMember
          name="Nathan Lowery"
          position="CTO"
          :image="require('../assets/images/team/s1-team2.jpg')"
          bio="Fascinated by science, technology and music from early childhood, Nathan has had the privilege of building a career on his experiences across many domains and industries to help founders and companies realize their full potential. His happy place is working alongside talented and motivated people whose aspirations are outshone only by a compassionate drive to reach their goals while building up and including those around them."
          linkedInLink="https://www.linkedin.com/in/nclowery/"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <TeamMember
          name="Carlos Osiris López"
          position="Sr. Blockchain Engineer"
          :image="require('../assets/images/team/s1-team3.jpg')"
          bio="Full Stack web developer with more than 7 years of experience. Carlos has a strong background in back-end development while keeping up to date with the latest front-end frameworks, web3, and blockchain development."
          twitterLink="https://twitter.com/ozzlopz"
          linkedInLink="https://www.linkedin.com/in/carlos-osiris-l%C3%B3pez-2a813566/"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <TeamMember
          name="Abbey (Abstract Artist)"
          position="Art Operations"
          :image="require('../assets/images/team/s1-team6.png')"
          bio="<p>Abstract Artist is a London-based Artist.</p><p> Her practice explores the idea of dérive landscapes through abstract painting and photography. She is analysing the impact of the environment on her behaviour. Abstract suffers from a chronic illness called Endometriosis. Her art is a spontaneous spur-of-the-moment landscape. Creating unplanned works deriving from her sensations & the frustration from battling Endometriosis. A vital aspect of her practice is capturing the glimpses of these significant responses to her life and translating them into art. Abstract’s work blurs the lines of abstraction with her narratives. She has displayed works in real-time and at virtual shows, including The Holy Art gallery in Hackney, The Shrine gallery in New York, and the Miami Bitcoin 2021 NFT lounge. Her previous experience entails working at various museums and galleries in London, U.K.</p>"
          twitterLink="https://twitter.com/AbbeyAbstract"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <TeamMember
          name="MJ"
          position="Advisor"
          :image="require('../assets/images/team/s1-team5.jpg')"
          bio="Co-Founder at BBCo/Non-Fungible Heroes.<br/> CSO, Consultant, Decentralized Art Enthusiast.<br/> Information Token, Fingerprints DAO."
          twitterLink="https://twitter.com/champagneman"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import TeamMember from '../components/team-member'

  export default defineComponent({
    name: 'About',
    components: {
      TeamMember,
    },
  })
</script>

<style scoped>
  .about-container {
    padding: 0 10px;
  }
  .s1-section-1 {
    vertical-align: top;
  }
  .left-container {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
  }
  .right-container {
    padding-bottom: 5px;
  }
  .img-logo {
    max-width: 400px;
    padding-right: 3%;
    width: 100%;
  }
  .description {
    margin: 1em 0;
    padding-bottom: 10px;
    line-height: 1.5em;
  }
  .description p {
    text-align: left;
  }
  .about-link {
    font-size: 1em;
  }
  .team-title {
    color: #fd9b3b;
  }
</style>
